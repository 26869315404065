import { type EmployeeInfo } from '../../types/site.types';

/**
 * Checks to see if employee is authenticated and siteViewPreference is 'Internal'
 */
export function showAsEmployee(employee: EmployeeInfo): boolean {
	return employee.isAuthenticated && employee.siteViewPreference === 'Internal';
}

/**
 * Replaces the substring 'Build with Ferguson' Build.com' with 'Ferguson Home'.
 */
export const replaceSiteName = (input: string): string => {
	return input.replace(/Build with Ferguson|Build\.com/gi, 'Ferguson Home');
};
