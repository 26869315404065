import { type FunctionComponent } from 'react';
import { handleKeys } from '../../../helpers/keyboard/keyboard.helper';
import { IconWithLabel, type IconWithLabelProps } from '../../icon/icon-with-label/icon-with-label.component';
import { ClickableElement } from '../index';

export type IconWithLabelButtonProps = {
	ariaLabel: string;
	onClick: any;
	disabled?: boolean;
	automationHook?: string;
	buttonClass?: string;
} & IconWithLabelProps;

export const IconWithLabelButton: FunctionComponent<IconWithLabelButtonProps> = ({
	automationHook = '',
	ariaLabel,
	onClick,
	disabled,
	buttonClass,
	...rest
}) => {
	const hoverClass = disabled ? 'hover-bg-theme-white' : `hover-bg-theme-${rest.theme}-lighter`;

	return (
		<ClickableElement
			ariaLabel={ariaLabel}
			onClick={onClick}
			onKeyDown={handleKeys([' ', 'Enter'], onClick)}
			disabled={disabled}
			automationHook={automationHook}
			className={buttonClass}>
			<IconWithLabel iconContainerClass={hoverClass} {...rest} />
		</ClickableElement>
	);
};
