import { type FunctionComponent, type ReactElement } from 'react';
import { Badge } from '../../common-components/badge/badge.component';
import { container } from './icon-with-label.css';

export type IconTheme = 'primary' | 'secondary' | 'internal' | 'error' | 'grey' | 'white';

export type IconWithLabelProps = {
	icon: ReactElement;
	label: string;
	theme?: IconTheme;
	iconContainerClass?: string;
	containerClass?: string;
	labelClass?: string;
	notSmallLabel?: string;
	badgeCount?: number;
	badgeClass?: string;
	hideBadgeCount?: boolean;
};

export const IconWithLabel: FunctionComponent<IconWithLabelProps> = ({
	icon,
	label,
	theme = 'grey',
	containerClass = '',
	labelClass = 'f7',
	iconContainerClass = '',
	notSmallLabel,
	badgeCount = 0,
	badgeClass = '',
	hideBadgeCount
}) => {
	return (
		<div className={`flex flex-column items-center ${containerClass} theme-${theme} ${container}`}>
			<div className={`flex items-center br2`}>
				<Badge count={badgeCount} hideCount={hideBadgeCount} noMargin={true} className={`flex ${badgeClass}`}>
					<div className={`flex ${iconContainerClass}`}>{icon}</div>
				</Badge>
			</div>
			{label && <p className={`${notSmallLabel ? 'dn-ns ' : ''}ma0 nowrap lh-title ${labelClass}`}>{label}</p>}
			{notSmallLabel && <p className={`dn flex-ns ma0 nowrap lh-title ${labelClass}`}>{notSmallLabel}</p>}
		</div>
	);
};
