import gql from 'graphql-tag';
import { AddressFields, ErrorFields, ImageFields, LocationFields } from '../common-fields.queries';

export const CUSTOMER = gql`
	${LocationFields}
	${ImageFields}
	query Customer {
		customer {
			id
			location {
				...LocationFields
			}
			profile {
				avatar {
					...ImageFields
				}
				type
				firstName
				lastName
				email
				phoneNumber
				company
			}
			storeCredit
			salesRepresentative {
				firstName
				lastName
				email
				avatar {
					...ImageFields
				}
				title
			}
			paymentOptions {
				hasOpenAccount
			}
			productViewPreference {
				preference
			}
			encryptedEmail
			unreadNotificationCount
		}
	}
`;

/*
 * Customer query to specifically grab auth status.  Note: we don't fetch this with
 * the CUSTOMER query used by our customer hook as we want to optimize that hook to leverage
 * cached data only.  We always want an up-to-date value for auth status, so it's fetched separately by
 * components that need it.
 */
export const CUSTOMER_IS_AUTHENTICATED = gql`
	query CustomerIsAuthenticated {
		customer {
			id
			isAuthenticated
		}
	}
`;

export const LOCATION = gql`
	query Location($zipCode: String!) {
		location(zipCode: $zipCode) {
			...LocationFields
		}
	}
	${LocationFields}
`;

export const SET_CUSTOMER_LOCATION = gql`
	${LocationFields}
	mutation SetCustomerLocation($zipCode: String!, $source: ZipCodeSourceEnum!) {
		setLocation(zipCode: $zipCode, source: $source) {
			...LocationFields
		}
	}
`;

export const ADDRESSES = gql`
	query Addresses {
		addresses {
			...AddressFields
		}
	}
	${AddressFields}
`;

export const BILLING_AND_SHIPPING_ADDRESSES = gql`
	${AddressFields}
	query BillingAndShippingAddress {
		customer {
			id
			addressInfo {
				billing {
					...AddressFields
				}
				shipping {
					...AddressFields
				}
			}
		}
	}
`;

export const SET_CUSTOMER_ADDRESS = gql`
	mutation Address($input: AddressInput!) {
		address(input: $input) {
			... on Address {
				...AddressFields
			}
			... on Error {
				...ErrorFields
			}
		}
	}
	${ErrorFields}
	${AddressFields}
`;

export const UPDATE_ADDRESS_STATUS = gql`
	mutation UpdateAddressStatus($addressId: Int!, $isActive: Boolean!) {
		updateAddressStatus(addressId: $addressId, isActive: $isActive)
	}
`;

export const UPDATE_CREDIT_CARD_STATUS = gql`
	mutation UpdateCreditCardStatus($creditCardId: Int!, $isDeleted: Boolean!) {
		updateCreditCardStatus(creditCardId: $creditCardId, isDeleted: $isDeleted)
	}
`;

export const SET_PRODUCT_VIEW_PREFERENCE = gql`
	mutation SetProductViewPreference($preference: ProductViewEnum!) {
		setProductViewPreference(preference: $preference) {
			preference
		}
	}
`;
