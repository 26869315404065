import { useCookies } from '@fergdigitalcommerce/fergy-react-components';
import { type FunctionComponent, useEffect, useState } from 'react';
import { type AnnouncementFieldsFragment } from '../../../__generated__/graphql-client-types';
import { TEN_YEAR_EXPIRATION } from '../../../constants/cookies';
import { ClickableElement } from '../../buttons';
import { CloseIcon } from '../../svg/icons.component';
import { bannerStyleReset, errorBannerLink, infoBannerLink, warningBannerLink } from './announcement-banner.css';

export const BANNER_SEVERITY_MAP = {
	LOW: `bg-theme-info-light b--theme-info ${infoBannerLink}`,
	MEDIUM: `bg-theme-warning-light b--theme-warning ${warningBannerLink}`,
	HIGH: `bg-theme-error-light b--theme-error ${errorBannerLink}`
};

const CLOSE_ICON_SEVERITY_MAP = {
	LOW: `theme-info`,
	MEDIUM: `theme-warning`,
	HIGH: `theme-error`
};

export type AnnouncementBannerProps = {
	announcement: AnnouncementFieldsFragment;
	dismissible?: boolean;
};

export const AnnouncementBanner: FunctionComponent<AnnouncementBannerProps> = ({ announcement, dismissible = true }) => {
	const { message, severity, id } = announcement;
	const { getCookie, setCookie } = useCookies();
	const cookieName = `hide-announcement-banner-${id}`;
	const isBannerEnabled = getCookie(cookieName) !== 'true';

	const [showAnnouncementBanner, setShowAnnouncementBanner] = useState(isBannerEnabled);

	const hideBanner = () => {
		setShowAnnouncementBanner(false);
		setCookie(cookieName, 'true', TEN_YEAR_EXPIRATION);
	};

	useEffect(() => {
		setShowAnnouncementBanner(isBannerEnabled);
	}, [isBannerEnabled]);

	return (
		<>
			{showAnnouncementBanner && (
				<div
					className={`bb ${BANNER_SEVERITY_MAP[severity]} f6 tc relative lh-copy ${bannerStyleReset}`}
					aria-label={`Site-wide Announcement`}
					data-testid="announcement-banner">
					<div
						className={`tc f6 theme-gray-dark b pv2 lh-solid mr5`}
						dangerouslySetInnerHTML={{
							__html: message
						}}
					/>
					{dismissible && (
						<ClickableElement
							ariaLabel="hide announcement banner"
							className={`flex items-center pointer lh-solid ${CLOSE_ICON_SEVERITY_MAP[severity]} absolute right-0 top-0 pt2 pr3`}
							onClick={hideBanner}
							onKeyDown={hideBanner}
							data-testid="close-icon">
							<CloseIcon className={`f5 pa0`} />
						</ClickableElement>
					)}
				</div>
			)}
		</>
	);
};
