import { CurrencyCode } from '../../../constants/currency';
import { TrackedEvent, TrackedEventCase } from '../event-types';
import {
	type GTMEventWrapper,
	type GTMAddToCartEvent,
	type GTMRemoveFromCartEvent,
	type GTMProduct,
	type GTMCartSummary,
	GTMCustomProductDimensions
} from './gtm-types';
import { type CartMutationResultCart, buildGTMCartProduct, generateCartSummary, wrapGTMEvent } from './gtm-utils.helper';

/**
 * Builds an add to cart event for GTM
 */
export const buildGTMAddToCart = (
	useCase: TrackedEventCase,
	gtmProducts: GTMProduct[],
	cartSummary: GTMCartSummary,
	currencyCode = CurrencyCode.UNITED_STATES,
	recommendedType?: string
): GTMEventWrapper<GTMAddToCartEvent> => {
	// Shim in useCase into the product dimension data due to reporting limitations in GA
	gtmProducts[0][GTMCustomProductDimensions.ADD_TO_CART_METHOD] = useCase;
	return wrapGTMEvent(
		TrackedEvent.ADD_TO_CART,
		useCase,
		{
			currencyCode,
			add: {
				products: gtmProducts
			},
			cartSummary
		},
		undefined,
		recommendedType
	);
};

/**
 * Builds a remove from cart event for GTM
 */
export const buildGTMRemoveFromCart = (
	useCase: TrackedEventCase,
	gtmProducts: GTMProduct[],
	cartSummary: GTMCartSummary
): GTMEventWrapper<GTMRemoveFromCartEvent> => {
	return wrapGTMEvent(TrackedEvent.REMOVE_FROM_CART, useCase, {
		remove: {
			products: gtmProducts
		},
		cartSummary
	});
};

/**
 * Helper function to generate the appropriate tracking event for item updates
 * from the cart page.
 */
export const buildGTMUpdateCartEvent = (
	updatedQuanity: number,
	cartItem,
	cart: CartMutationResultCart
): GTMEventWrapper<GTMAddToCartEvent> | GTMEventWrapper<GTMRemoveFromCartEvent> => {
	const updateAmount = Math.abs(updatedQuanity);
	const updatedItem: GTMProduct = buildGTMCartProduct(cartItem, updateAmount);
	const cartSummary = generateCartSummary(cart);
	if (updatedQuanity > 0) {
		return buildGTMAddToCart(TrackedEventCase.ADD_TO_CART_CART_PAGE, [updatedItem], cartSummary);
	} else {
		return buildGTMRemoveFromCart(TrackedEventCase.ADD_TO_CART_CART_PAGE, [updatedItem], cartSummary);
	}
};
