import { matchPath } from 'react-router';
import { extractPathName } from '../../../utils/string/url.utils';
import { HOUR_IN_MS } from '../../constants/time';
import { liveRoutes } from '../../routes/routes';
import { doesWindowExist } from '../../utils/dom/dom.utils';

export type MatchedPath = { [x: string]: any } | null;

/**
 * Searches the routes to find a match and preload those webpack assets
 */
export const preloadComponentForUrl = (url: string) => {
	const matchingRoute = findLiveRoute(url);
	return matchingRoute?.component?.preload?.();
};

/**
 * Out of the provided routes will match a url on path
 */
export const findRoute = (url: string, routes): MatchedPath => {
	// This check is implemented to force rerender of the page
	// if the user is an internal user and has not navigated in the last hour
	if (doesWindowExist() && window.dataLayer?.isInternalUser) {
		// Get the current time and the last navigation time
		const { performance } = window;

		// If it has been an hour since last nav, don't use SPA routing
		if (performance.now() > HOUR_IN_MS) {
			return null;
		}
	}

	return routes.find(({ path, exact }) => {
		const pathName = extractPathName(url);
		return matchPath(pathName, { path, exact });
	});
};

/**
 * Checks to see if provided url is live on the react experience
 * Used primarily to determine if routing should be done via the react
 * router or by a full page refresh
 */
export const findLiveRoute = (url: string): MatchedPath => findRoute(url, liveRoutes);
