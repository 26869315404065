import { type FunctionComponent, useState } from 'react';
import { FEATURE_FLAGS, OVERLAY_MARKER } from '../../../constants/general';
import { HOMEPAGE_LINK, SHOWROOM_LINK, SUPPORT_FAQ_LINK, SUPPORT_HOME_LINK } from '../../../constants/links';
import { useFeature } from '../../../hooks/features/features.hooks';
import { Link } from '../../common-components/link/link.component';
import { ProjectHeaderDropdown } from '../../project-components/project-header-dropdown/project-header-dropdown.component';
import { BuildWFergusonSvg } from '../../svg/general.component';
import { AccountDropdown } from '../account-dropdown/account-dropdown.component';
import { AnnouncementBanner } from '../announcement-banner/announcement-banner.component';
import { CallCenterLink } from '../call-center-link/call-center-link.component';
import { CartLink } from '../cart-link/cart-link.component';
import { ChatButton } from '../chat-button/chat-button.component';
import { DesktopNav } from '../desktop-nav/desktop-nav.component';
import { GlobalContentConstruct } from '../global-content-construct/global-content-construct.component';
import { MobileAccountLink } from '../mobile-account-link/mobile-account-link.component';
import { MobileNav } from '../mobile-nav/mobile-nav.component';
import { NotificationsBadge } from '../notifications-badge/notifications-badge.component';
import { ProSignupLink } from '../pro-signup-link/pro-signup-link.component';
import { SearchBar } from '../search-bar/search-bar.component';
import { UniversalLocationIndicator } from '../universal-location-indicator/universal-location-indicator.component';
import { navigationStyles } from './header.css';

export const Header: FunctionComponent = () => {
	const [isTypeaheadOpen, setIsTypeaheadOpen] = useState<boolean>(false);
	const isOmnihomeAnnouncementBannerActive = useFeature<boolean>(FEATURE_FLAGS.OMNI_ANNOUNCEMENT_BANNER);
	return (
		<header className="bg-theme-white dn-p" data-testid="headerComponent">
			{isOmnihomeAnnouncementBannerActive && (
				<AnnouncementBanner
					announcement={{
						message: `Build with Ferguson is becoming Ferguson Home. <a href=${SUPPORT_HOME_LINK}>Learn more</a>`,
						severity: 'LOW',
						id: 'build-announcement'
					}}
					dismissible={false}
				/>
			)}
			<div className="bt-ns bw2-ns b--theme-primary f6 sans-serif">
				<div className="flex-ns flex-row-ns justify-end-ns center mw9">
					<ProSignupLink isBuild={true} />
					<div className={`${navigationStyles} bg-theme-grey-lighter-ns br-pill br--bottom fn fr-ns pv0 pv1-ns`}>
						<nav className="flex flex-row justify-end items-center h-100 h-auto-ns fn fr-ns mh4-ns" aria-label="Helpful links">
							<Link
								url={HOMEPAGE_LINK}
								className={`dib dn-ns h-100 w-20 ml2 mr-auto`}
								ariaLabel="Navigate to home page"
								color="grey">
								<BuildWFergusonSvg className="h-100 w-100" />
							</Link>
							<div className="db dn-ns ph2 ph0-ns mr3-ns">
								<MobileAccountLink iconClass="f3" labelClass="f6" />
							</div>
							<div className="db dn-ns ph2 ph0-ns mr3-ns">
								<ProjectHeaderDropdown />
							</div>
							<div className=" ph2 ph0-ns mr1 mr3-ns">
								<CallCenterLink iconClass="f3 f5-ns" theme="grey" labelClass="f6" containerClass={'flex-row-ns'} />
							</div>
							<div className="tc dn db-ns ml2">
								<ChatButton automationHook="header-chat" iconClass="f5" labelClass="f6" theme="grey" />
							</div>
							<Link url={SHOWROOM_LINK} className="dn db-ns mh3 hover-theme-primary" color="grey">
								Showrooms
							</Link>
							<Link url={SUPPORT_FAQ_LINK} className="dn db-ns hover-theme-primary" color="grey">
								Help
							</Link>
							<div className="h-100">
								<CartLink isMobile={true} />
							</div>
						</nav>
					</div>
				</div>
				{/* Desktop Only */}
				<div className={`mw9 dn db-ns mb2 center`}>
					<div className={`flex flex-row justify-between items-end w-100`}>
						<Link url={HOMEPAGE_LINK} className={`w-10 dib h3`} ariaLabel="Navigate to home page" color="grey">
							<BuildWFergusonSvg className="ml2-m h-100 w-100" />
						</Link>

						<div className="flex flex-column flex-nowrap pt2 pl4 w-90 relative">
							{/* Settings z-index to z-2 because it needs to be above the search bar which is z-1*/}
							<div className="absolute top--1 z-2">
								<UniversalLocationIndicator />
							</div>
							<SearchBar onTypeaheadOpenChanged={setIsTypeaheadOpen} />
							<div className="fr flex justify-between items-center self-end pl4 pr3 w-60">
								<ProjectHeaderDropdown />
								<AccountDropdown />
								<NotificationsBadge isHidden={isTypeaheadOpen} />
								<div className="f5 b flex flex-column flex-row-ns items-center justify-center">
									<CartLink />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className={`${OVERLAY_MARKER} dn db-ns`} />
				<DesktopNav />
				<GlobalContentConstruct />
				{/* Mobile Only */}
				<MobileNav />
				<div className={`${OVERLAY_MARKER} dn-ns`} />
			</div>
		</header>
	);
};
