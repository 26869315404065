import { type FunctionComponent, type PropsWithChildren } from 'react';
import { useLiveChat } from '../../../hooks/analytics/live-chat/live-chat.hooks';
import { IconWithLabelButton } from '../../buttons/icon-with-label-button/icon-with-label-button.component';
import { type IconTheme } from '../../icon/icon-with-label/icon-with-label.component';
import { SmsIcon } from '../../svg/icons.component';

const ChatToggle: FunctionComponent<
	PropsWithChildren<{
		className?: string;
		iconClass?: string;
		labelClass?: string;
		containerClass?: string;
		theme?: IconTheme;
		automationHook?: string;
		onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	}>
> = ({ onClick, labelClass, iconClass = 'flex', containerClass = 'flex-row-ns', automationHook, theme }) => {
	return (
		<IconWithLabelButton
			onClick={onClick}
			automationHook={automationHook}
			theme={theme}
			label="Chat"
			notSmallLabel="Chat With Us"
			labelClass={labelClass}
			buttonClass="pv2 pv0-ns"
			icon={<SmsIcon className={iconClass} />}
			containerClass={containerClass}
			ariaLabel={'Chat Button'}
		/>
	);
};

export type ChatButtonProps = {
	containerClass?: string;
	iconClass?: string;
	labelClass?: string;
	automationHook?: string;
	theme?: IconTheme;
};
/**
 * ChatButton - renders a button to initiate a chat.
 *
 * @param {ChatButtonProps} chatButtonProps
 */
export const ChatButton: FunctionComponent<ChatButtonProps> = ({ containerClass, iconClass, labelClass, automationHook, theme }) => {
	const openLiveChat = useLiveChat();
	return (
		<ChatToggle
			onClick={openLiveChat}
			iconClass={iconClass}
			labelClass={labelClass}
			automationHook={automationHook}
			theme={theme}
			containerClass={containerClass}
		/>
	);
};
