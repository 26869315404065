import { logError } from 'fergy-core-react-logging';
import { useEffect, useState } from 'react';
import useScript from 'react-script-hook';
import { useIsBuildDomain } from '../apollo/site/site.hooks';

const MEDALLIA_SCRIPT = 'https://nebula-cdn.kampyle.com/us/wu/1193605/onsite/embed.js';
const MEDALLIA_BUILD_FORM_ID = 52181;
const MEDALLIA_FERGHOME_FORM_ID = 52183;

export const useMedallia = () => {
	useScript({
		src: MEDALLIA_SCRIPT,
		checkForExisting: true
	});
	const isBuild = useIsBuildDomain();
	const [isMedalliaLoaded, setIsMedalliaLoaded] = useState(false);

	const formId = isBuild ? MEDALLIA_BUILD_FORM_ID : MEDALLIA_FERGHOME_FORM_ID;

	const openMedalliaForm = () => {
		window.KAMPYLE_ONSITE_SDK.showForm(formId);
	};

	const onMedalliaLoaded = () => {
		const formLoaded = window.KAMPYLE_ONSITE_SDK.loadForm(formId);
		if (formLoaded) {
			setIsMedalliaLoaded(true);
		} else {
			logError(`Unable to load medallia form ${formId}`);
		}
	};

	useEffect(() => {
		const handleMedalliaLoad = () => {
			onMedalliaLoaded();
		};

		if (window.KAMPYLE_ONSITE_SDK) {
			onMedalliaLoaded();
		} else {
			window.addEventListener('neb_OnsiteLoaded', handleMedalliaLoad);
		}
		return () => {
			window.removeEventListener('neb_OnsiteLoaded', handleMedalliaLoad);
		};
	}, []);

	return { openMedalliaForm, isMedalliaLoaded };
};
