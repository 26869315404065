import { type FunctionComponent } from 'react';
import { ACCOUNT_LINK } from '../../../constants/links';
import { useCustomer } from '../../../hooks/apollo/customer/customer.hooks';
import { IconWithLabelLink } from '../../common-components/link/icon-with-label-link/icon-with-label-link.component';
import { type IconTheme } from '../../icon/icon-with-label/icon-with-label.component';
import { PersonIcon } from '../../svg/icons.component';

// TODO: EFDC-16856: reduce use of custom css for header
export const MobileAccountLink: FunctionComponent<{ iconClass?: string; labelClass?: string; theme?: IconTheme }> = ({
	iconClass,
	labelClass,
	theme = 'grey'
}) => {
	const { data: customerData } = useCustomer();
	const unreadNotificationCount = customerData?.customer?.unreadNotificationCount ?? 0;

	return (
		<IconWithLabelLink
			url={ACCOUNT_LINK}
			badgeCount={unreadNotificationCount}
			badgeClass="omniMobileAccountBadge"
			hideBadgeCount={true}
			label="Account"
			icon={<PersonIcon className={iconClass} />}
			labelClass={labelClass}
			ariaLabel="Account Link"
			theme={theme}
			linkClass="pv2"
		/>
	);
};
