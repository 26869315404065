import gql from 'graphql-tag';
import { ImageFields } from '../common-fields.queries';

const HoursFields = gql`
	fragment HoursFields on Hours {
		startTime
		endTime
	}
`;

const OpenRangeFields = gql`
	fragment OpenRangeFields on TimeRange {
		start
		end
	}
`;

const ExceptionsFields = gql`
	${HoursFields}
	fragment ExceptionsFields on HoursException {
		date
		fullDayClosure
		adjustedHours {
			...HoursFields
		}
	}
`;

export const SITE_INFO = gql`
	${ImageFields}
	query SiteInfo($subCategoriesLimit: Int) {
		siteInfo {
			siteDomain
			dataLayer {
				keys {
					googleMapsApi
					googlePlacesApi
					dynamicYieldKey
					salesForce
					recaptchaSiteId
				}
			}
			navigation {
				id
				name
				url
				image {
					...ImageFields
				}
				subCategories(limit: $subCategoriesLimit) {
					id
					name
					url
					image {
						...ImageFields
					}
				}
			}
		}
	}
`;

export const CONTACT_INFO = gql`
	${HoursFields}
	${OpenRangeFields}
	${ExceptionsFields}
	query ContactInfo {
		contactInfo {
			phone {
				openRange {
					...OpenRangeFields
				}
				number
				weekdayHours {
					...HoursFields
				}
				weekendHours {
					...HoursFields
				}
				exceptions {
					...ExceptionsFields
				}
			}
			chat {
				openRange {
					...OpenRangeFields
				}
				weekdayHours {
					...HoursFields
				}
				weekendHours {
					...HoursFields
				}
				exceptions {
					...ExceptionsFields
				}
			}
		}
	}
`;

export const EMPLOYEE = gql`
	query Employee {
		employee {
			id
			fullName
			email
			isAuthenticated
			siteViewPreference
		}
	}
`;

export const SET_SITE_VIEW_PREFERENCE = gql`
	mutation SetSiteViewPreference($preference: SiteViewEnum!) {
		setSiteViewPreference(preference: $preference)
	}
`;
