import { inputDebounceTime } from '../utils/dom/dom.utils';

/**
 * Max query length allowed.
 */
export const MAX_QUERY_LENGTH = 250;

/**
 * Number of characters required in search input before typeahead suggestions will be fetched.
 */
export const SAYT_MIN_QUERY_LENGTH = 3;

/**
 * Debounce time for typing in the search bar input to trigger a SAYT request.
 */
export const SAYT_DEBOUNCE_TIME = inputDebounceTime(150);

/**
 * Default number of typeahead terms to fetch.
 */
export const SAYT_DEFAULT_TERMS_LIMIT = 10;

/**
 * Default number of typeahead products to fetch.
 */
export const SAYT_DEFAULT_PRODUCTS_LIMIT = 6;
