import { type FunctionComponent } from 'react';
import { CART_LINK } from '../../../constants/links';
import { useCartHeader } from '../../../hooks/apollo/cart-header/cart-header.hooks';
import { IconWithLabelLink } from '../../common-components/link/icon-with-label-link/icon-with-label-link.component';
import { type IconTheme } from '../../icon/icon-with-label/icon-with-label.component';
import { CartIcon } from '../../svg/icons.component';
import { cartIconStyles, cartQuantityLeft } from './cart-link.css';

type CartQuantityIndicatorProps = {
	quantity: number;
	isMinimizedHeader?: boolean;
};

const CartQuantityIndicator: FunctionComponent<CartQuantityIndicatorProps> = ({ quantity, isMinimizedHeader }) => {
	const isOver99 = quantity > 99;
	const modifiedFontSize = isOver99 ? `f7` : '';

	return (
		<div className={`h2 tc ${isMinimizedHeader ? '' : 'omniHomeCartIcon'}`}>
			<div
				className={`relative top-0 omniHomeCartPosition-ns
					omniCartQuantityPosition
				 ${cartQuantityLeft} ${!isMinimizedHeader && !isOver99 ? 'omni-f5-ns' : ''} ${modifiedFontSize}`}
				data-automation="header-cart-quantity-amount">
				{isOver99 ? '99+' : quantity}
			</div>
			<CartIcon className={`f2 ${cartIconStyles} ${isMinimizedHeader ? '' : 'omniCartIcon'}`} />
		</div>
	);
};

export type CartLinkProps = {
	isMobile?: boolean;
	isBuild?: boolean;
	isMinimizedHeader?: boolean;
};

// TODO: EFDC-16856: reduce use of custom css for header
export const CartLink: FunctionComponent<CartLinkProps> = ({ isMobile = false, isBuild = true, isMinimizedHeader }) => {
	const { quantity } = useCartHeader();
	let themeColor: IconTheme;

	if (!isBuild || isMinimizedHeader) {
		themeColor = 'primary';
	} else {
		themeColor = isMobile ? 'white' : 'grey';
	}

	let containerClass = '';
	let linkClass = '';
	let labelClass = 'dn';
	if (isBuild && isMobile) {
		containerClass = 'dn-ns bg-theme-primary pa2 order-2 h-100 justify-center';
	}
	if (!isBuild && isMobile) {
		labelClass = 'f7 omniHomeCartLabel';
		linkClass = 'pv2 w-100 flex justify-center';
	}

	return (
		<IconWithLabelLink
			label="Cart"
			icon={<CartQuantityIndicator quantity={quantity} isMinimizedHeader={isMinimizedHeader} />}
			ariaLabel={`View ${quantity} items currently in cart`}
			automationHook={`header-cart${isMobile ? '-mobile' : ''}`}
			iconContainerClass={!isMinimizedHeader ? 'omniCartScale' : ''}
			containerClass={`${containerClass} ${isMinimizedHeader ? '' : 'omniHomeCartItem omniMobileCartDisplay'}`}
			url={CART_LINK}
			theme={themeColor}
			linkClass={linkClass}
			labelClass={labelClass}
		/>
	);
};
