import { type FunctionComponent } from 'react';
import { IconWithLabel, type IconWithLabelProps } from '../../../icon/icon-with-label/icon-with-label.component';
import { Link } from '../link.component';

export type IconWithLabelLinkProps = {
	ariaLabel: string;
	url: string;
	automationHook?: string;
	linkClass?: string;
} & IconWithLabelProps;

export const IconWithLabelLink: FunctionComponent<IconWithLabelLinkProps> = ({
	ariaLabel,
	url,
	linkClass,
	automationHook = '',
	...rest
}) => {
	return (
		<Link ariaLabel={ariaLabel} url={url} automationHook={automationHook} className={linkClass}>
			<IconWithLabel {...rest} />
		</Link>
	);
};
