import { type FunctionComponent } from 'react';
import { PAGE_CONTENT_STYLE } from '../../../constants/general';
import { SUPPORT_EMAIL_US_LINK } from '../../../constants/links';
import { useContactInfo } from '../../../hooks/apollo/site/site.hooks';

export const ErrorCommon: FunctionComponent = () => {
	const {
		phone: { number: phoneNumber }
	} = useContactInfo();

	return <ErrorContent phoneNumber={phoneNumber} />;
};

/**
 * Default to use ErrorCommon instead. ErrorContent should only use primitive html elements
 * as it won't be able to consume apollo data or use routing since it's rendered outside
 * our normal app tree which contains contexts used by our more complex components.
 */
export const ErrorContent: FunctionComponent<{ phoneNumber: string }> = ({ phoneNumber }) => (
	<div className={PAGE_CONTENT_STYLE}>
		<h1>There was an error with your request</h1>
		<p>
			We are always working on building you a better site, and that page might be in a new location or is being improved. Try using
			our search bar or the links provided below to find what you are looking for.
		</p>
		<h2>Possible reasons for this error:</h2>
		<ul>
			<li>We moved the location of the page on our servers</li>
			<li>The bookmark you used is outdated</li>
			<li>The URL was entered incorrectly</li>
		</ul>
		<h2>Need help?</h2>
		<ul>
			<li>
				<a className="theme-primary" href={SUPPORT_EMAIL_US_LINK}>
					Email Us
				</a>
			</li>
			<li className="mt2">
				Call <span>{phoneNumber}</span>
			</li>
		</ul>
	</div>
);
