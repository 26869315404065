import { type FunctionComponent, type HTMLAttributes } from 'react';
import { type Maybe } from '../../../__generated__/graphql-client-types';
import { NO_IMAGE_URL } from '../../../constants/images';
import { type CloudinaryOptions, generateCloudinaryUrl } from '../../../helpers/cloudinary-helper/cloudinary-helper';
import { type Variation, fallbackImage, generateDimensionAttributes, generateSrcSetData } from '../../../helpers/images/images.helper';
import { BaseImage } from '../base-image/base-image.component';

export const defaultCloudinaryOptions: CloudinaryOptions = {
	width: 330,
	height: 320
};

export type CloudinaryImageProps = {
	bypassLazyLoad?: boolean;
	className?: string;
	description: string;
	draggable?: boolean;
	ignoreChromatic?: boolean;
	limitHeight?: boolean;
	actualImageProps?: Partial<Omit<HTMLAttributes<HTMLImageElement>, 'alt' | 'className'>>;
	options: CloudinaryOptions;
	placeholderOptions?: CloudinaryOptions;
	publicID?: string;
	useDimensions?: boolean; // determines if width and height attributes will be applied to the img tag, can be passed false to disable this behavior
	variations?: Variation[];
	lowQualityPlaceholder?: Maybe<string>;
};

/**
 * renders an img or picture with proper cloudinary url(s)
 * The variations are to serve responsive images which yield a better user
 * experience when rehydrating the app
 */
export const CloudinaryImage: FunctionComponent<CloudinaryImageProps> = ({
	bypassLazyLoad,
	className,
	description,
	draggable,
	ignoreChromatic,
	limitHeight = false,
	actualImageProps = {},
	options,
	publicID = NO_IMAGE_URL,
	useDimensions = true,
	variations = [],
	placeholderOptions,
	lowQualityPlaceholder
}) => {
	const primaryOptions = { ...options, type: options?.type || 'private' };
	const { height = 0, width = 0 } = primaryOptions;
	const source = generateCloudinaryUrl(publicID, primaryOptions);
	const sourceSetData = generateSrcSetData(publicID, primaryOptions, variations);
	const shouldUseDimensions = useDimensions && variations.length === 0;
	const dimensions = generateDimensionAttributes(width, height);

	let placeholderSrc;
	if (lowQualityPlaceholder) {
		placeholderSrc = lowQualityPlaceholder;
	} else if (placeholderOptions) {
		placeholderSrc = generateCloudinaryUrl(publicID, placeholderOptions);
	} else {
		placeholderSrc = fallbackImage;
	}

	return (
		<BaseImage
			actualImageProps={actualImageProps}
			bypassLazyLoad={bypassLazyLoad}
			className={className}
			description={description}
			dimensions={dimensions}
			draggable={draggable}
			ignoreChromatic={ignoreChromatic}
			limitHeight={limitHeight}
			lowQualityPlaceholder={placeholderSrc}
			shouldUseDimensions={shouldUseDimensions}
			source={source}
			sourceSetData={sourceSetData}
		/>
	);
};
