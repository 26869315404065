import { type FunctionComponent } from 'react';
import { useMedallia } from '../../../hooks/medallia/medallia.hooks';
import { TextButton, type TextButtonProps } from '../text-button/text-button.component';

type MedalliaButtonProps = Pick<TextButtonProps, 'color'>;

export const MedalliaButton: FunctionComponent<MedalliaButtonProps> = ({ color = 'white' }) => {
	const { openMedalliaForm, isMedalliaLoaded } = useMedallia();
	return (
		<TextButton color={color} underlineHover={true} onClick={openMedalliaForm} disabled={!isMedalliaLoaded}>
			Feedback
		</TextButton>
	);
};
