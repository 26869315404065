/**
 * Functions responsible for returning GTM event payloads.
 * Function names should start with "build" and should return an event type
 */
import {
	type CustomerTypeEnum,
	type MessageTypeEnum,
	type ProductSearchInput,
	type ProductViewEnum,
	type SearchVariantFieldsFragment
} from '../../../__generated__/graphql-client-types';
import { type DYAnalyticsData } from '../../../types/analytics.types';
import { type ProductSearchResult, type SearchProduct } from '../../../types/search.types';
import { type ShowroomBookApptEvent, ShowroomBookingStepNames, ShowroomBookingSteps } from '../../../types/showroom.types';
import { type AddToCartProductFamily } from '../add-to-cart-analytics.helper';
import { TrackedEvent, TrackedEventCase } from '../event-types';
import { buildGTMAddToCart } from './cart-event-builders';
import {
	type GTMAddToCartEvent,
	type GTMCustomEvent,
	GTMCustomProductDimensions,
	type GTMCustomerLoginEvent,
	type GTMDYVariationImpressionEvent,
	type GTMEventWrapper,
	type GTMImpression,
	type GTMPageData,
	type GTMSearchProductClickEvent,
	type GTMShowroomBookingEvent,
	type GTMViewSearchResultsEvent
} from './gtm-types';
import {
	type CartMutationResultCart,
	buildGTMProduct,
	generateCartSummary,
	wrapGTMEvent,
	generateTrackingSearchTerm
} from './gtm-utils.helper';

/**
 * returns the GTM page-view event payload
 */
export const buildGTMPageViewData = (pageData: GTMPageData): GTMCustomEvent => {
	const { page, baseCategory = '', businessCategory = '', brand = '', customer, employee, collection = '' } = pageData;
	/* These properties must exist on the object, even if they are undefined */
	const eventData: GTMCustomEvent = {
		event: TrackedEvent.PAGE_VIEW,
		page,
		baseCategory,
		brand,
		businessCategory,
		customer,
		employee,
		collection
	};
	return eventData;
};

/**
 * Convenience method to build GTM data for add-to-cart event types.
 * Note that it only accepts domain/primitive types so
 * that client code need not 'know' about GTM-specific models.
 */
export const buildGTMAddToCartEvent = (
	useCase: TrackedEventCase,
	variantId: number,
	quantity: number,
	productFamily: AddToCartProductFamily,
	cartMutationResult: CartMutationResultCart,
	recommendedType?: string
): GTMEventWrapper<GTMAddToCartEvent> => {
	const addedProduct = buildGTMProduct(
		productFamily,
		productFamily.variants.find((variant) => variant.id === variantId) || productFamily.variants[0],
		quantity
	);

	const cartSummary = generateCartSummary(cartMutationResult);

	return buildGTMAddToCart(useCase, [addedProduct], cartSummary, undefined, recommendedType);
};

export const buildGTMCustomerLoginEvent = (
	customerType: CustomerTypeEnum,
	isNewAccount: boolean,
	userDBID: number
): GTMCustomerLoginEvent => {
	return {
		event: TrackedEvent.CUSTOMER_LOGIN,
		customerType,
		isNewAccount,
		userDBID
	};
};

export const buildGTMDYVariationImpressionEvent = (dyAnalyticsData: DYAnalyticsData): GTMDYVariationImpressionEvent => {
	const { campaignName, experienceName, variationName } = dyAnalyticsData;
	return {
		event: TrackedEvent.DY_EVENT,
		eventAction: campaignName,
		eventCategory: 'DY Smart Object',
		eventLabel: `${experienceName} (${variationName})`
	};
};

export const buildGTMShowroomBookingEvent = (
	flow: 'cancel' | 'do-not-cancel' | 'flow' | 'login',
	event?: ShowroomBookApptEvent
): GTMShowroomBookingEvent => {
	if (flow === 'cancel') {
		return {
			event: TrackedEvent.SHOWROOM_BOOKING_FLOW,
			step: 'cancel-booking-flow',
			step_name: TrackedEventCase.CANCELLED_BOOKING_FLOW
		};
	} else if (flow === 'do-not-cancel') {
		return {
			event: TrackedEvent.SHOWROOM_BOOKING_FLOW,
			step: 'return-to-booking-flow',
			step_name: TrackedEventCase.RETURN_TO_BOOKING_FLOW
		};
	} else if (flow === 'login') {
		return {
			event: TrackedEvent.SHOWROOM_BOOKING_FLOW,
			step: flow,
			step_name: TrackedEventCase.PLEASE_LOGIN_TO_CONTINUE
		};
	} else {
		let step;
		let step_name;
		if (event) {
			const { type, detail, bookingDetail } = event;
			// the salesforce implementation sends a separate event labeled "gtm" with booking detail
			// to prevent sending two "review" events for an individual booking, we only send the review stage with the booking detail
			if (type === 'booking-id' && bookingDetail) {
				return {
					event: TrackedEvent.SHOWROOM_BOOKING_FLOW,
					step: ShowroomBookingSteps['review'],
					step_name: ShowroomBookingStepNames['review'],
					bookingDetail
				};
			} else if (type === 'stage' && detail !== 'review') {
				// will leave "review" event as undefined
				step = ShowroomBookingSteps[detail];
				step_name = ShowroomBookingStepNames[detail];
			} else if (type === 'finish' || type === 'cancel') {
				step = type;
				step_name = type === 'finish' ? TrackedEventCase.FINISH : TrackedEventCase.ATTEMPT_TO_CANCEL;
			}
		}
		return {
			event: TrackedEvent.SHOWROOM_BOOKING_FLOW,
			step,
			step_name
		};
	}
};

/**
 * Builds a GTM event to report product clicks
 */
export const buildGTMProductClick = (
	product: SearchProduct,
	eventType: TrackedEventCase,
	trackingList: string,
	cardIndex: number,
	variant?: SearchVariantFieldsFragment
): GTMEventWrapper<GTMSearchProductClickEvent> => {
	return wrapGTMEvent(TrackedEvent.PRODUCT_CLICK, eventType, {
		click: {
			actionField: {
				list: trackingList
			},
			products: [
				{
					brand: product.brandName,
					[GTMCustomProductDimensions.COLLECTION]: product.collection?.name,
					[GTMCustomProductDimensions.PRODUCT_ID]: product.modelNumber,
					id: product.id.toString(),
					name: `${product.brandName} ${product.modelNumber}`,
					price: product.priceInfo.current,
					variant: variant?.name,
					position: cardIndex + 1,
					// TODO: add these to schema later (mostly from product family)
					category: undefined,
					[GTMCustomProductDimensions.BUSINESS_CATEGORY]: undefined,
					[GTMCustomProductDimensions.PRODUCT_TYPE]: undefined,
					[GTMCustomProductDimensions.PRODUCT_APPLICATION]: undefined
				}
			]
		}
	});
};

export const buildGTMWriteReviewEcrmOrderPage = (): GTMCustomEvent => {
	return {
		event: TrackedEvent.ECRM_ORDER_EVENT,
		type: TrackedEventCase.WRITE_REVIEW_ORDER_PAGE_SECTION
	};
};

export const buildGTMCollectionLinkClick = (collectionName: string, modelNumber: string): GTMCustomEvent => ({
	event: TrackedEvent.COLLECTION_LINK_CLICKED,
	collectionName,
	modelNumber
});

export const buildGTMCartDrawerRecommendations = (recommendationType: string): GTMCustomEvent => ({
	event: TrackedEvent.CART_DRAWER_IMPRESSION,
	type: recommendationType
});

export const buildGTMPromoBannerClick = (priority: string, label: string): GTMCustomEvent => ({
	event: TrackedEvent.PROMO_BANNER_CLICK,
	priority,
	label: label || null
});

export const buildGTMPromoBannerDisplay = (priority?: string): GTMCustomEvent => ({
	event: TrackedEvent.PROMO_BANNER_DISPLAY,
	ecommerce: {
		display: {
			actionField: { priority: priority || '' }
		}
	}
});

export const buildGTMVariationGroup = (title: string, position: string): GTMCustomEvent => ({
	event: TrackedEvent.PRODUCT_DETAIL_VIEW,
	type: TrackedEventCase.PRODUCT_DETAIL_LOAD,
	title,
	position
});

export const buildGTMSelectedVariation = (variationName: string, variantId: number): GTMCustomEvent => ({
	event: TrackedEvent.PRODUCT_CONFIGURATION,
	type: TrackedEventCase.PRODUCT_VARIATION_SELECTED,
	label: variationName,
	variationSelected: variantId
});

export const buildGTMChangeShippingMethod = (shippingSpeed: string, shippingMethod: string): GTMCustomEvent => ({
	event: TrackedEvent.CHANGE_SHIPPING_METHOD,
	shippingSpeed,
	shippingMethod
});

export const buildGTMHeaderProjectsClick = (type?: 'individual-select' | 'view-more' | null): GTMCustomEvent => ({
	event: TrackedEvent.HEADER_PROJECTS_CLICK,
	type: type ? (type === 'individual-select' ? TrackedEventCase.VIEW_PROJECT : TrackedEventCase.VIEW_ALL_PROJECTS) : null
});

export const buildGTMHeaderNoProjectsClick = (): GTMCustomEvent => ({
	event: TrackedEvent.HEADER_NO_PROJECTS_CLICK
});

export const buildGTMHeaderNotificationsClick = (): GTMCustomEvent => ({
	event: TrackedEvent.HEADER_NOTIFICATIONS_CLICK,
	type: TrackedEventCase.OPEN_NOTIFICATION_FEED
});

export const buildGTMEcrmNotificationsClick = (): GTMCustomEvent => ({
	event: TrackedEvent.ECRM_NOTIFICATIONS_CLICK,
	type: TrackedEventCase.OPEN_NOTIFICATION_FEED
});

export const buildGTMNotificationTabClick = (type: 'all' | 'orders' | 'projects' | 'settings'): GTMCustomEvent => {
	const typeMap = {
		all: TrackedEventCase.VIEW_ALL_NOTIFICATIONS,
		orders: TrackedEventCase.VIEW_ORDER_NOTIFICATIONS,
		projects: TrackedEventCase.VIEW_PROJECT_NOTIFICATIONS,
		settings: TrackedEventCase.VIEW_NOTIFICATION_SETTINGS
	};
	return {
		event: TrackedEvent.HEADER_NOTIFICATIONS_CLICK,
		type: typeMap[type]
	};
};

export const buildGTMNotificationClick = (type: MessageTypeEnum | null): GTMCustomEvent => ({
	event: TrackedEvent.NOTIFICATION_CLICK,
	type
});

// SAVE TO PROJECTS MULTI ADD BUILDERS
export const buildGTMOpenSaveToProjectsModal = (): GTMCustomEvent => ({
	event: TrackedEvent.PROJECTS_MULTI_ADD,
	type: TrackedEventCase.OPEN_MODAL
});

export const buildGTMNewProjectClick = (): GTMCustomEvent => ({
	event: TrackedEvent.PROJECTS_MULTI_ADD,
	type: TrackedEventCase.NEW_PROJECT_MULTI_SELECT
});

export const buildGTMNewGroupClick = (): GTMCustomEvent => ({
	event: TrackedEvent.PROJECTS_MULTI_ADD,
	type: TrackedEventCase.NEW_GROUP_MULTI_SELECT
});

export const buildGTMSelectGroupClick = (): GTMCustomEvent => ({
	event: TrackedEvent.PROJECTS_MULTI_ADD,
	type: TrackedEventCase.SELECT_GROUP
});

export const buildGTMEditProjectQuantity = (): GTMCustomEvent => ({
	event: TrackedEvent.PROJECTS_MULTI_ADD,
	type: TrackedEventCase.QUANTITY_CHANGE
});

export const buildGTMCompleteSaveClick = (type = TrackedEventCase.COMPLETE_SAVING): GTMCustomEvent => ({
	event: TrackedEvent.PROJECTS_MULTI_ADD,
	type
});

export const buildGTMCancelClick = (): GTMCustomEvent => ({
	event: TrackedEvent.PROJECTS_MULTI_ADD,
	type: TrackedEventCase.CANCEL_CLICK
});

export const buildGTMProjectContainsImportedBid = (projectId: number): GTMCustomEvent => ({
	event: TrackedEvent.PAGE_READY,
	type: TrackedEventCase.PROJECTS_HAS_IMPORTED_BID,
	projectId
});

export const buildGTMSblViewCompleteLook = (): GTMCustomEvent => ({
	event: TrackedEvent.SHOP_BY_LOOK_PDP,
	type: TrackedEventCase.VIEW_COMPLETE_LOOK
});

export const buildGTMSblFavoriteLook = (): GTMCustomEvent => ({
	event: TrackedEvent.SHOP_BY_LOOK_PDP,
	type: TrackedEventCase.FAVORITE_LOOK
});

export const buildGTMSblExpandContainer = (): GTMCustomEvent => ({
	event: TrackedEvent.SHOP_BY_LOOK_PDP,
	type: TrackedEventCase.EXPAND_CONTAINER
});

export const buildGTMSblViewAllLooks = (): GTMCustomEvent => ({
	event: TrackedEvent.SHOP_BY_LOOK_PDP,
	type: TrackedEventCase.VIEW_ALL_LOOKS
});

export const buildGTMSblIsDisplayed = (familyId: number): GTMCustomEvent => ({
	event: TrackedEvent.QUICK_STARTS_PDP,
	type: TrackedEventCase.SBL_IS_DISPLAYED,
	label: familyId
});

export const buildGTMVideoModalOpened = (): GTMCustomEvent => ({
	event: TrackedEvent.PDP_MEDIA_GALLERY_REORDER,
	type: TrackedEventCase.VIDEO_MODAL_OPENED
});

export const buildGTMImageSelection = (): GTMCustomEvent => ({
	event: TrackedEvent.PDP_MEDIA_GALLERY_REORDER,
	type: TrackedEventCase.IMAGE_SELECTION
});

export const buildGTMPackageBuilderAddToCart = (): GTMCustomEvent => ({
	event: TrackedEvent.ADD_TO_CART,
	type: TrackedEventCase.PACKAGE_BUILDER_ADD_TO_CART
});

export const buildGTMViewSearchResults = (
	eventType: TrackedEvent,
	useCase: TrackedEventCase,
	gtmImpressions: GTMImpression[],
	searchRequest: ProductSearchInput,
	searchResults?: ProductSearchResult,
	viewType?: ProductViewEnum,
	nonstock = false
): GTMEventWrapper<GTMViewSearchResultsEvent> => {
	return {
		...wrapGTMEvent(
			eventType,
			useCase,
			{
				impressions: gtmImpressions
			},
			generateTrackingSearchTerm(searchResults, searchRequest.query ?? '')
		),
		categoryId: searchRequest.categoryId,
		numberOfResults: searchResults?.count,
		pageNumber: searchRequest.offset + 1,
		resultsPerPage: searchRequest.limit,
		sortBy: searchRequest.sortOption,
		viewType: viewType === 'LIST' ? 'list' : 'tile',
		nonstock
	};
};

/**
 * Builds a GTM event for tracking interaction with the suggestion calculator
 */
export const buildGTMSuggestionCalculator = (type: 'calculator-loaded' | 'calculator-used', calcType: string | null): GTMCustomEvent => ({
	event: TrackedEvent.SUGGESTION_CALCULATOR,
	type,
	calcType
});
