import { type FunctionComponent, useRef, useState } from 'react';
import { DOHERTY_THRESHOLD, FEATURE_FLAGS } from '../../../constants/general';
import { useContactInfo, useContactMethodStatus } from '../../../hooks/apollo/site/site.hooks';
import { useFeature } from '../../../hooks/features/features.hooks';
import { IconWithLabelLink } from '../../common-components/link/icon-with-label-link/icon-with-label-link.component';
import { Popover } from '../../common-components/popover/popover.component';
import { type IconTheme } from '../../icon/icon-with-label/icon-with-label.component';
import { CallIcon } from '../../svg/icons.component';

// TODO: EFDC-16856: reduce use of custom css for header
const PhoneToggle: FunctionComponent<{
	phoneNumber: string;
	iconClass?: string;
	theme?: IconTheme;
	containerClass?: string;
	labelClass?: string;
}> = ({ phoneNumber, labelClass, iconClass, containerClass, theme }) => {
	return (
		<IconWithLabelLink
			label="Call"
			notSmallLabel={phoneNumber}
			labelClass={labelClass}
			icon={<CallIcon className={iconClass} />}
			theme={theme}
			linkClass="flex justify-center pv2 pv0-ns w-100"
			url={`tel:${phoneNumber}`}
			containerClass={containerClass}
			ariaLabel={'Call Link'}
		/>
	);
};

export type CallCenterLinkProps = {
	// depending on context (product pages), a custom phone can be rendered
	phoneOverride?: string;
	customLabel?: string;
	iconClass?: string;
	theme?: IconTheme;
	containerClass?: string;
	labelClass?: string;
};

/**
 * CallCenterLink - renders a link to the call center or display available phone hours in a popover
 */
export const CallCenterLink: FunctionComponent<CallCenterLinkProps> = ({ phoneOverride, theme, iconClass, containerClass, labelClass }) => {
	const [isPhoneHoursOpen, setIsPhoneHoursOpen] = useState(false);
	const closeMenuTimer = useRef(0);

	// this is a killswitch for cases when we have issues with phone providers.
	const isCallCenterEnabled = useFeature(FEATURE_FLAGS.CONTACT_CENTER_ENABLED);

	const isOpen = useContactMethodStatus('phone');
	const { phone: phoneInfo } = useContactInfo();
	const phoneNumber = phoneOverride ?? phoneInfo.number;

	const mouseEnter = () => {
		clearTimeout(closeMenuTimer.current);
		setIsPhoneHoursOpen(true);
	};
	const mouseLeave = () => {
		closeMenuTimer.current = window.setTimeout(() => setIsPhoneHoursOpen(false), DOHERTY_THRESHOLD);
	};

	if (!isCallCenterEnabled) {
		return null;
	}

	return isOpen ? (
		<PhoneToggle
			phoneNumber={phoneNumber}
			theme={theme}
			iconClass={iconClass}
			containerClass={containerClass}
			labelClass={labelClass}
		/>
	) : (
		<Popover
			isVisible={isPhoneHoursOpen}
			setIsVisible={setIsPhoneHoursOpen}
			toggleElement={
				<div data-testid="phone-toggle-wrapper" onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} role="button" tabIndex={0}>
					<PhoneToggle
						phoneNumber={phoneNumber}
						theme={theme}
						iconClass={iconClass}
						containerClass={containerClass}
						labelClass={labelClass}
					/>
				</div>
			}
			direction="bottom">
			<div data-testid="phone-popover" className="pa1 f6 lh-copy" style={{ width: '11rem' }}>
				<div className="b">Mon-Fri</div>
				<div>
					{phoneInfo.weekdayHours.startTime} - {phoneInfo.weekdayHours.endTime} PST
				</div>
				<div className="b">Sat &amp; Sun</div>
				<div>
					{phoneInfo.weekendHours.startTime} - {phoneInfo.weekendHours.endTime} PST
				</div>
				{phoneInfo.exceptions.map((exception, index) => (
					<div key={index}>
						<div className="b">{exception.date}</div>
						<div>
							{exception.fullDayClosure ? (
								<>Closed</>
							) : (
								<>
									{exception.adjustedHours?.startTime} - {exception.adjustedHours?.endTime} PST
								</>
							)}
						</div>
					</div>
				))}
			</div>
		</Popover>
	);
};
