import { VersionedRouter } from '@fergdigitalcommerce/fergy-core-react-web/esmDist/components/versioned-router';
import { createBrowserHistory, type LocationListener } from 'history';
import { type FunctionComponent, useEffect } from 'react';
import { type RouteProps, type RouterProps } from 'react-router';
import { JUMB_TAB_ANCHOR_PREFIX } from '../../constants/common-component.constants';
import { scrollToElement } from '../../utils/dom/dom.utils';

function hookHistoryBoomerang() {
	const BOOMR = window['BOOMR'];
	if (BOOMR && BOOMR.version && BOOMR.plugins && BOOMR.plugins.History) {
		BOOMR.plugins.History.hook(history, true);
		return true;
	}
	return false;
}

export type BuildRouterProps = {
	// your props
	onLocationChange?: (pathName: string, search: string) => void;
	exclude: RouteProps[]; // Route
} & Partial<RouterProps>;

const browserHistory = createBrowserHistory();

function makeLocationChangeHandler(onLocationChange?: (pathName: string, search: string) => void): LocationListener {
	return (location) => {
		if (location.hash) {
			// An exception for jump tabs
			if (!location.hash.startsWith(`#${JUMB_TAB_ANCHOR_PREFIX}`)) {
				scrollToElement(location.hash);
			}
		} else {
			window.scrollTo(0, 0);
		}

		if (onLocationChange) {
			onLocationChange(location.pathname, location.search);
		}
	};
}

/**
 * The purpose of this component is to generate a router that is in charge of keeping track of the history
 * of the site. Mainly for analytical purposes.
 */
export const BuildRouter: FunctionComponent<BuildRouterProps> = ({ children, onLocationChange, history = browserHistory, exclude }) => {
	// History cannot change per react-router, so make this a one-time effect.
	useEffect(() => {
		if (!hookHistoryBoomerang()) {
			document.addEventListener('onBoomerangLoaded', hookHistoryBoomerang);
		}

		const unregisterLocationListener = history?.listen(makeLocationChangeHandler(onLocationChange));

		return () => {
			if (unregisterLocationListener) {
				unregisterLocationListener();
			}
		};
	}, [history, onLocationChange]);

	return (
		<VersionedRouter history={history} exclude={exclude}>
			{children}
		</VersionedRouter>
	);
};
